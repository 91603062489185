:root {
  font-size: 16px;

  --primary-color: #060181;
  --secondary-color: #f74a4b;
}

body {
  font-size: 1rem;
}

a {
  color: #1b1b1c;

  &:hover {
    color: var(--secondary-color);
  }
}
